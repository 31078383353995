<template>
  <div class="trigger-pda-wrapper">
    <h3 class="config-title">
      {{ $t('lang.gles.workflow.triggerPDAConfig') }}
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t(padConfigTip)"
        placement="top-start"
      >
        <i class="el-icon-info" />
      </el-tooltip>
    </h3>
    <div v-if="mode !== 'detail'" class="btn-wrapper">
      <el-button type="text" icon="el-icon-circle-plus" @click="resetAdd(true)">
        {{ $t('lang.gles.common.add') }}
      </el-button>
      <el-button
        type="text"
        icon="el-icon-delete-solid"
        class="danger"
        :disabled="!nodeList.length"
        @click="handleDeleteAllNode"
      >
        {{ $t('lang.gles.common.clearEmpty') }}
      </el-button>
    </div>
    <!-- 显示区 -->
    <div class="node-list">
      <el-card v-for="(item, i) in nodeList" :key="item.id" class="pda-card">
        <m-form
          ref="editForm"
          :key="item.id"
          :form-data="getBaseFormData(item)"
          :extend-config="getViewFormExtendConfig(item)"
          :button="button"
          label-position="right"
          :label-width="80"
          class="prop-form"
          @submit="(model) => handleEditNode(model, item)"
          @reset="handleToggleEdit(i, item, false)"
        />
        <el-button-group
          v-if="!item.isEdit && mode !== 'detail'"
          class="node-btn-wrapper"
        >
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="handleToggleEdit(i, item, true)"
          />
          <el-button
            type="text"
            icon="el-icon-delete-solid"
            class="danger"
            @click="handleDeleteNode(item)"
          />
        </el-button-group>
      </el-card>
    </div>
    <!-- 添加 -->
    <el-card v-if="isAdd" class="pda-card mt10">
      <m-form
        ref="addForm"
        :form-data="baseFormData"
        :extend-config="formExtendConfig"
        :button="button"
        label-position="right"
        :label-width="80"
        class="prop-form"
        @submit="handleAddNode"
        @reset="resetAdd(false)"
      />
    </el-card>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { getCarryPropFormData } from '../data'
import commonMixins from '@/mixins/commonMixins'
export default {
  mixins: [commonMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    workTemplateId: {
      type: Number,
      default: null
    },
    pNodeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      // 节点列表
      nodeList: [],
      isAdd: false,
      padConfigTip: 'lang.gles.workflow.carryProp.padConfigTip',
      formExtendConfig: {
        justify: '',
        labelWidth: '50px'
      },
      button: {
        ok: this.$t('lang.gles.common.confirm'),
        cancel: this.$t('lang.gles.common.cancel')
      },
      factoryPositionList: [],
      formModel: {
        isEdit: true,
        triggerPosition: null,
        triggerOperate: null,
        triggerTask: null
      },
      checkedFactoryPositions: []
    }
  },
  computed: {
    ...mapState('workflow', ['pdaTriggerOperateList', 'pdaTriggerTaskList']),
    baseFormData() {
      return getCarryPropFormData(this, { ...this.formModel })
    }
  },
  watch: {
    factoryPositionList() {
      this.filterCheckedFactoryPosition()
    }
  },
  mounted() {
    this.initSelectList()
    this.getDmpNodeList()
  },
  methods: {
    async initSelectList() {
      await this.getFactoryPositionList({ usableFlag: 0 })
      if (!this.pdaTriggerOperateList?.length) {
        this.$store.dispatch('workflow/getPdaTriggerOperate')
      }
      if (!this.pdaTriggerTaskList?.length) {
        this.$store.dispatch('workflow/getPdaTriggerTask')
      }
    },
    getBaseFormData(formModel) {
      return getCarryPropFormData(this, { ...formModel })
    },
    getViewFormExtendConfig(notice = {}) {
      return {
        isNeedBtn: notice.isEdit,
        justify: '',
        labelWidth: '50px'
      }
    },
    handleToggleEdit(i, item, isEdit) {
      this.$set(this.nodeList, i, { ...item, isEdit })
      this.resetAdd(false)
    },
    async getDmpNodeList() {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getDmpNodeList'),
        { workTemplateId: this.workTemplateId }
      )
      if (code) return
      this.nodeList = data
      this.checkedFactoryPositions = data.map((t) => t.triggerPosition)
      this.filterCheckedFactoryPosition()
      this.$emit('update:pNodeList', this._.cloneDeep(this.nodeList))
    },
    filterCheckedFactoryPosition() {
      this.factoryPositionList.forEach((t) => {
        t.disabled = this.checkedFactoryPositions.includes(t.value)
      })
    },
    /**
     * 确认添加节点
     */
    async handleAddNode(model = {}) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('addOrEditDmpNode'),
        {
          workTemplateId: this.workTemplateId,
          ...this.formModel
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.workflow.operateSuccess'))
      this.getDmpNodeList()
      this.resetAdd(false)
    },
    resetAdd(flag) {
      this.formModel = {
        isEdit: true
      }
      this.isAdd = flag
    },
    /**
     * 表单项改变事件
     */
    onPropFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
    },
    /**
     * 清空节点
     */
    async handleDeleteAllNode() {
      // 二次确认
      this.$confirm(this.$t('lang.gles.workflow.pda.confirmMsg1'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.emptyNode()
      })
    },
    async emptyNode() {
      // 清空节点
      const { code } = await this.$httpService(
        this.$apiStore.workflow('emptyDmpNode'),
        { workTemplateId: this.workTemplateId }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.saveSuccessfully'))
      this.getDmpNodeList()
    },
    /**
     * 修改节点
     */
    async handleEditNode(model, node) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('addOrEditDmpNode'),
        {
          id: node.id,
          workTemplateId: node.workTemplateId,
          ...model
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.saveSuccessfully'))
      this.getDmpNodeList()
      this.resetAdd(false)
    },
    /**
     * 删除节点
     */
    async handleDeleteNode(node = {}) {
      // 二次确认
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteNode(node)
      })
    },

    /**
     * 接口删除节点
     */
    async deleteNode(node = {}) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('deleteDmpNode'),
        {
          id: node.id
        }
      )
      if (code !== 0) {
        return
      }
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.getDmpNodeList()
    }
  }
}
</script>
<style lang="scss" scoped>
.trigger-pda-wrapper {
  position: relative;
  width: 260px;
  flex: 0 0 260px;
  // min-height: calc(100vh - 220px);
  // max-height: calc(100vh - 100px);
  padding: 0 10px 10px 10px;
  border: 1px solid #f0f0f0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0;
  }
  .pda-card {
    padding: 10px;
    overflow-y: scroll;
    /deep/ .el-card__body {
      height: 100%;
      padding: 0;
    }
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .config-title {
    font-size: 16px;
    line-height: 40px;
    color: #409eff;
    .el-icon-info {
      color: #409eff;
    }
  }
  .node-btn-wrapper {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    /deep/ .el-icon-edit,
    /deep/.el-icon-delete-solid {
      margin-right: 10px;
      font-size: 14px;
    }
  }
}
</style>
