<template>
  <div class="workflow basic-data-add">
    <header-path v-if="mode !== 'detail'" :paths="headerPaths" />
    <el-card class="add-card">
      <m-form
        ref="myForm"
        :form-data="baseFormData"
        :extend-config="getExtendConfig()"
        label-position="right"
        :label-width="140"
        :button="button"
        class="basic-data-add-form"
        @submit="onSubmit"
        @reset="onCancel"
      >
        <template #inputCodeTipIcon>
          <el-tooltip
            popper-class="workshop_code_tip-icon"
            effect="dark"
            :content="$t(autoGeneCodeTip)"
            placement="right"
          >
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
        <template #carryPositionTipIcon>
          <el-tooltip popper-class="workshop_code_tip-icon" effect="dark" :content="$t(carryPositionTip)" placement="right">
            <i class="el-icon-tip el-icon-question" />
          </el-tooltip>
        </template>
      </m-form>
    </el-card>
  </div>
</template>
<script>
import _ from 'lodash'
import HeaderPath from '@/components/headerPath/index.vue'
import MForm from '@/libs_sz/components/MForm/MForm'
import { getEditBaseFormData } from '../data'
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'

export default {
  name: 'WorkTemplateAdd',
  components: {
    MForm,
    HeaderPath
  },
  mixins: [addMixins, commonMixins],
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    },
    from: {
      type: String,
      default: 'WorkTemplateList'
    }
  },
  data() {
    return {
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0
      },
      formModel: {
        carryPosition: null,
        executeMode: 0,
        templateCode: '',
        templateName: '',
        robotType: '',
        execTemplate: '',
        templateStatus: 1
      },
      copyTarget: {},
      nodeList: [],
      // 机器人执行模板
      execTemplateList: [],
      carryPositionTip: `第三方获取： 通过工位配置第三方提供接口获取目标库位信息；
系统指定： 系统配置上料/下料取料或放料库位/库区，GMS根据指定库位/库位顺序执行搬运任务。
人工指定： PDA/ 接口下发搬运模式任务，需指定取放料库位/ 库区，GMS根据指定库位/库位顺序执行搬运任务。`,
      putMaterialPositionList: [],
      takeMaterialPositionList: [],
      currentExecuteModeList: [],
      defaultExecTemplateCode: ''
    }
  },
  computed: {
    ...mapState('workflow', ['robotTypeList', 'allocStrategyList', 'carryPositionList', 'takeMaterialTypeList', 'putMaterialTypeList', 'executeModeList']),
    baseFormData() {
      const row = this.formModel || {}
      return getEditBaseFormData(this, { ...row })
    },
    headerPaths() {
      return this.mode === 'add' ? [this.$t('lang.gles.workflow.workTemplateAdd')] : [this.$t('lang.gles.workflow.workTemplateEdit')]
    },
    /**
     * 搬运模式
     * 系统指定
     */
    isShowCarryPosition() {
      return this.formModel.carryPosition && this.formModel.carryPosition === 3 && this.formModel.executeMode === 1
    }
  },
  watch: {
    executeModeList: {
      handler() {
        this.currentExecuteModeList = _.cloneDeep(this.executeModeList)
      },
      immediate: true
    }
  },
  created() {
    this.initSelectList()
    if (this.mode !== 'add') {
      this.formModel = { ...this.rowDetail }
      this.copyTarget = { ...this.rowDetail }
      this.setTemplateAndExecuteModeList(this.formModel.robotType)
      this.getExecTemplateList({ robotType: this.formModel.robotType })
      this.getTakeMaterialPositionList(this.formModel.takeMaterialType)
      this.getPutMaterialPositionList(this.formModel.putMaterialType)
    }
    if (this.mode !== 'edit') {
      this.getSerialBizCode('workTemplateSerialNum', 'templateCode')
      // 实时节点分配
      this.formModel.allocStrategy = 1
    }
  },
  methods: {
    async initSelectList() {
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
      if (!this.allocStrategyList?.length) {
        this.$store.dispatch('workflow/getAllocStrategyList')
      }
      if (!this.carryPositionList?.length) {
        this.$store.dispatch('workflow/getCarryPositionList')
      }
      if (!this.takeMaterialTypeList?.length) {
        this.$store.dispatch('workflow/getTakeMaterialTypeList')
      }
      if (!this.putMaterialTypeList?.length) {
        this.$store.dispatch('workflow/getPutMaterialTypeList')
      }
      if (!this.executeModeList?.length) {
        this.$store.dispatch('workflow/getExecuteModeList')
      }
    },
    getExtendConfig() {
      return {
        isNeedBtn: this.mode !== 'detail'
      }
    },
    async getTemplateDetail(params) {
      const { data, code } = await this.$httpService(
        this.$apiStore.base('queryTemplateDetail'),
        params
      )
      if (code) return
      const { nodeList } = data
      this.nodeList = nodeList
    },
    /**
     * 机器人类型切换事件
     * 1、 机器人类型双举升： 执行模板加载GMS复合机器人模板数据；默认填充KL-ROBOT;
       2、 机器人类型悬臂：执行模板加载GMS复合机器人模板数据；默认填充KA-ROBOT;
       3、 机器人类型举升： 执行模板加载GMS举升机器人模板数据；默认填充ML-ROBOT；
     */
    async handleRobotType(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      await this.getExecTemplateList({ robotType: Number(val) })
      this.setTemplateAndExecuteModeList(val)
      // 执行模式
      this.formModel.executeMode = this.setDefaultExecuteMode()
      const execTemplate = this.execTemplateList?.find((t) => t.value === this.defaultExecTemplateCode)
      this.formModel.execTemplate = execTemplate ? this.defaultExecTemplateCode : ''
      this.formModel.execTemplateRobotType = execTemplate
        ? execTemplate.robotType
        : 1
    },
    /**
     * 设置默认执行模板
     * 设置当前执行模式可选列表
     */
    setTemplateAndExecuteModeList(robotType) {
      // 联动  机器人执行模板
      // let defaultExecTemplateCode = ''
      switch (robotType) {
        case 1:
          this.defaultExecTemplateCode = 'KL-ROBOT'
          this.currentExecuteModeList = this.executeModeList.filter(t => t.value === 0)
          break
        case 2:
          this.defaultExecTemplateCode = 'KA-ROBOT'
          this.currentExecuteModeList = this.executeModeList.filter(t => t.value === 0)
          break
        case 3:
          this.defaultExecTemplateCode = 'ML-ROBOT'
          this.currentExecuteModeList = _.cloneDeep(this.executeModeList)
          break
        case 4:
          this.currentExecuteModeList = this.executeModeList.filter(t => t.value === 1)
        case 5:
          this.currentExecuteModeList = _.cloneDeep(this.executeModeList)
          break
        default:
          break
      }
    },
    /**
     * 设置搬运模式默认值
     */
    setDefaultExecuteMode() {
      if ([1, 2, 3].includes(Number(this.formModel.robotType))) {
        return 0
      } else if (Number(this.formModel.robotType) === 4) {
        return 1
      } else {
        return 0
      }
    },
    /**
     * 执行模式
     */
    handleExecuteMode(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      this.resetFieldByExecuteMode(this.formModel)
      this.formModel.allocStrategy = 1
      this.formModel.carryPosition = ''
    },
    /**
     * 机器人执行模板
     */
    handleExecTemplate(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      this.formModel.execTemplateRobotType =
        this.execTemplateList?.find((t) => t.value === val)?.robotType ?? 1
    },
    handleCarryPositionChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      if (val === 3) {
        this.formModel.takeMaterialType = 1
        this.formModel.putMaterialType = 1
        this.getTakeMaterialPositionList(this.formModel.takeMaterialType)
        this.getPutMaterialPositionList(this.formModel.putMaterialType)
      }
    },
    /**
     * 取料类型
     */
    async handleTakeMaterialTypeChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      this.formModel.takeMaterialPosition = null
      if (!val) {
        this.takeMaterialPositionList = []
        return
      }
      this.getTakeMaterialPositionList(val)
    },
    /**
     * 放料类型
     */
    handlePutMaterialTypeChange(val, formItem) {
      this.$set(this.formModel, formItem.name, val)
      this.formModel.putMaterialPosition = null
      if (!val) {
        this.putMaterialPositionList = []
        return
      }
      this.getPutMaterialPositionList(val)
    },
    /**
     * 获取取料位置
     */
    getTakeMaterialPositionList(takeMaterialType) {
      const api = takeMaterialType === 1 ? 'getFactoryPositionDictList' : takeMaterialType === 2 ? 'getWarehouseAreaDictList' : 'getAreaDictList'
      this.getSelectOptions(api).then(
        (list) => {
          this.takeMaterialPositionList = list
        }
      )
    },
    /**
     * 获取放料位置
     */
    getPutMaterialPositionList(putMaterialType) {
      const api = putMaterialType === 1 ? 'getFactoryPositionDictList' : putMaterialType === 2 ? 'getWarehouseAreaDictList' : 'getAreaDictList'
      this.getSelectOptions(api).then(
        (list) => {
          this.putMaterialPositionList = list
        }
      )
    },
    /**
     * 根据执行模式重置字段值
     */
    resetFieldByExecuteMode(model = {}) {
      // 标准模式
      if (model.executeMode === 0) {
        model.carryPosition = ''
        model.takeMaterialType = ''
        model.takeMaterialPosition = ''
        model.putMaterialType = ''
        model.putMaterialPosition = ''
      }
      // 搬运模式
      if (model.executeMode === 1) {
        model.allocStrategy = ''
      }
    },
    /**
     * 保存
     */
    onSubmit() {
      // 先校验
      this.$refs.myForm.getValidateFormModel().then(async(model) => {
        const params = { ...this.rowDetail, ...this.formModel }
        this.resetFieldByExecuteMode(params)
        if (this.mode === 'copy') {
          if (this.copyTarget.templateCode === params.templateCode) {
            this.$message.error(this.$t('lang.gles.workflow.checkMessage4'))
            return
          }
          if (this.copyTarget.templateName === params.templateName) {
            this.$message.error(this.$t('lang.gles.workflow.checkMessage5'))
            return
          }
        }

        this.mode !== 'edit'
          ? await this.insert(params)
          : await this.update(params)
      })
    },
    /**
     * 添加
     */
    async insert(params) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('addWorkTemplate'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    /**
     * 更新
     */
    async update(params) {
      const { code } = await this.$httpService(
        this.$apiStore.workflow('updateWorkTemplate'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.updateSuccessfully'))
      this.onCancel()
    },
    /**
     * 复制模板时，保存节点信息
     */
    async saveNode(params) {
      const { code } = await this.$httpService(
        this.$apiStore.base('saveNode'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.addSuccessfully'))
      this.onCancel()
    },
    /**
     * 取消
     */
    onCancel() {
      this.$emit('updateCom', {
        currentCom: this.from
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.workflow {
  &.basic-data-add {
    .basic-data-add-form {
      width: 90%
    }
  }
}
</style>
