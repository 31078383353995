import mapVerify from '@/utils/formValidate'
import myTransform from '@/libs_sz/utils/transform'

const getFormatter = (data, value) => {
  const getValue = myTransform.arrToObject(data, 'value', 'label')[value] || value
  return getValue === 'null' || getValue === 0 ? '' : getValue
}
// 获取更多查询字段列表
export const getMoreQueryFormData = (that, formModel) => {
  const moreQueryData = [
    // 模板编码
    {
      name: 'templateCode',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.templateCode'),
      clearable: true
    },
    // 模板名称
    {
      name: 'templateName',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.templateName'),
      clearable: true
    },
    // 机器人类型
    {
      name: 'robotType',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.robotType'),
      clearable: true,
      filterable: true,
      data: that.robotTypeList,
      mchange: that.handleRobotType
    },
    {
      name: 'execTemplate',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.execTemplate'),
      clearable: true,
      filterable: true,
      data: that.execTemplateList
    },
    {
      name: 'templateStatus',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.base.usableFlag'),
      data: that.statusFlagList
    }
  ]
  return moreQueryData
}
// 查询列表列表
export const getSearchTableItem = (that) => {
  const searchTableItem = [
    // 模板编码
    {
      prop: 'templateCode',
      label: `${that.$t('lang.gles.workflow.templateCode')}`,
      isShow: true,
      slotName: 'clickSlot',
      sortable: true,
      minWidth: 140
    },
    // 模板名称
    {
      prop: 'templateName',
      label: that.$t('lang.gles.workflow.templateName'),
      isShow: true
    },
    // 机器人类型
    {
      prop: 'robotType',
      label: `${that.$t('lang.gles.workflow.robotType')}`,
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.robotTypeList, row[column])
      }
    },
    // 执行模式
    {
      prop: 'executeMode',
      label: `${that.$t('lang.gles.workflow.executeMode')}`,
      isShow: true,
      formatter(row, column) {
        return getFormatter(that.executeModeList, row[column])
      }
    },
    // 货位分配机制
    {
      prop: 'allocStrategy',
      label: `${that.$t('lang.gles.workflow.allocStrategy')}`,
      isShow: true,
      formatter(row, column) {
        return that.getDictLabel(that.allocStrategyList, row[column])
      }
    },
    // 搬运库位
    {
      prop: 'carryPosition',
      label: `${that.$t('lang.gles.workflow.carryPosition')}`,
      isShow: true,
      formatter(row, column) {
        return getFormatter(that.carryPositionList, row[column])
      }
    },
    // 机器人执行模板
    {
      prop: 'execTemplate',
      label: `${that.$t('lang.gles.workflow.execTemplate')}`,
      isShow: true
    },
    {
      prop: 'templateStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      slotName: 'usableFlag',
      showOverflowTooltip: false,
      minWidth: 84
    },
    {
      prop: 'createUser',
      label: `${that.$t('lang.gles.common.createUser')}`,
      isShow: true
    },
    {
      prop: 'createTime',
      label: `${that.$t('lang.gles.common.createTime')}`,
      isShow: true,
      minWidth: 180
    },
    {
      prop: 'updateUser',
      label: `${that.$t('lang.gles.common.updateUser')}`,
      isShow: true
    },
    {
      prop: 'updateTime',
      label: `${that.$t('lang.gles.common.updateTime')}`,
      isShow: true,
      minWidth: 180
    }
  ]
  searchTableItem.forEach((item) => (item.showOverflowTooltip = true))
  return searchTableItem
}

// 编辑弹框 基础信息
export const getEditBaseFormData = (that, row) => {
  const formData = [
    // 机器人类型
    {
      name: 'robotType',
      value: row.robotType || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.robotType'),
      rules: mapVerify(['required']),
      data: that.robotTypeList,
      clearable: true,
      mchange: that.handleRobotType,
      disabled: that.mode !== 'add'
    },
    // 执行模式
    {
      name: 'executeMode',
      value: row.executeMode ?? 0,
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.executeMode'),
      rules: mapVerify(['required']),
      data: that.currentExecuteModeList,
      clearable: true,
      mchange: that.handleExecuteMode,
      disabled: that.mode === 'detail'
    },
    // 机器人执行模板
    {
      name: 'execTemplate',
      value: row.execTemplate || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.execTemplate'),
      rules: mapVerify(['required']),
      disabled: that.mode === 'detail',
      data: that.execTemplateList,
      mchange: that.handleExecTemplate
    },
    // 模板编码
    {
      name: 'templateCode',
      value: row.templateCode || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.templateCode'),
      clearable: true,
      rules: mapVerify(['required', 'inputCode']),
      disabled:
        that.mode === 'detail' ||
        (that.mode === 'edit' && row.templateStatus === 0),
      appendSlotName: 'inputCodeTipIcon',
      class: 'tip-icon'
    },
    // 模板名称
    {
      name: 'templateName',
      value: row.templateName || '',
      span: 12,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.templateName'),
      clearable: true,
      rules: mapVerify(['required', 'inputName']),
      disabled: that.mode === 'detail'
    },
    // 货位分配机制
    {
      name: 'allocStrategy',
      value: row.allocStrategy || '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.allocStrategy'),
      disabled:
        that.mode !== 'edit' || (that.mode === 'edit' && !!row.templateStatus),
      data: that.allocStrategyList || [],
      rules: mapVerify(['required']),
      condition() {
        return row.executeMode === 0
      }
    },
    // 搬运库位
    {
      name: 'carryPosition',
      value: row.carryPosition ?? '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.carryPosition'),
      data: that.carryPositionList || [],
      rules: mapVerify(['required']),
      condition() {
        return row.executeMode === 1
      },
      appendSlotName: 'carryPositionTipIcon',
      class: 'tip-icon',
      disabled: that.mode === 'detail',
      mchange: that.handleCarryPositionChange
    },
    // 取料类型
    {
      name: 'takeMaterialType',
      value: row.takeMaterialType ?? 1,
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.takeMaterialType'),
      data: that.takeMaterialTypeList || [],
      condition() {
        return !!that.isShowCarryPosition
      },
      mchange: that.handleTakeMaterialTypeChange,
      disabled: that.mode === 'detail'
    },
    // 取料位置
    {
      name: 'takeMaterialPosition',
      value: row.takeMaterialPosition ?? '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.takeMaterialPosition'),
      data: that.takeMaterialPositionList || [],
      condition() {
        return !!that.isShowCarryPosition
      },
      disabled: that.mode === 'detail'
    },
    // 放料类型
    {
      name: 'putMaterialType',
      value: row.putMaterialType ?? 1,
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.putMaterialType'),
      data: that.putMaterialTypeList || [],
      condition() {
        return !!that.isShowCarryPosition
      },
      mchange: that.handlePutMaterialTypeChange,
      disabled: that.mode === 'detail'
    },
    // 放料位置
    {
      name: 'putMaterialPosition',
      value: row.putMaterialPosition ?? '',
      span: 12,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.putMaterialPosition'),
      data: that.putMaterialPositionList || [],
      condition() {
        return !!that.isShowCarryPosition
      },
      disabled: that.mode === 'detail'
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onFormChange
    }
  })
  return formData
}
// 节点属性表单
export const getPropFormData = (that, row) => {
  const formData = [
    // 节点名称
    {
      name: 'nodeName',
      value: that.$t(row.nodeName) || '',
      span: 24,
      component: 'elInput',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.nodeName'),
      rules: mapVerify(['required', 'inputName']),
      isShow: true,
      disabled: that.mode === 'detail'
    },
    // 节点描述
    {
      name: 'nodeDescription',
      value: row.nodeDescription || '',
      span: 24,
      component: 'elInput',
      type: 'textarea',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.nodeDescription'),
      clearable: true,
      isShow: true,
      disabled: that.mode === 'detail',
      rules: mapVerify(['inputLen200'])
    },
    // 取料类型
    {
      name: 'takeMaterialType',
      value: row.takeMaterialType || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.takeMaterialType'),
      rules: mapVerify(['required']),
      data: that.materialTypePropList,
      isShow: that.propKeys.includes('takeMaterialType'),
      disabled: that.mode === 'detail'
    },
    // 移出容器
    {
      name: 'shiftOutContainer',
      value: row.shiftOutContainer || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.shiftOutContainer'),
      rules: mapVerify(['required']),
      data: that.shiftInFactoryPositionPropList,
      isShow: that.propKeys.includes('shiftOutContainer'),
      disabled: that.mode === 'detail' || that.isWorkGoodsPositionDisabled
    },
    // 下料类型
    {
      name: 'unloadMaterialType',
      value: row.unloadMaterialType || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.unloadMaterialType'),
      rules: mapVerify(['required']),
      data: that.materialTypePropList,
      isShow: that.propKeys.includes('unloadMaterialType'),
      disabled: that.mode === 'detail'
    },
    // 放料类型
    {
      name: 'putMaterialType',
      value: row.putMaterialType || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.putMaterialType'),
      rules: mapVerify(['required']),
      data: that.materialTypePropList,
      isShow: that.propKeys.includes('putMaterialType'),
      disabled: that.mode === 'detail'
    },
    // 上料类型
    {
      name: 'loadMaterialType',
      value: row.loadMaterialType || '1',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.loadMaterialType'),
      rules: mapVerify(['required']),
      data: that.materialTypePropList,
      isShow: that.propKeys.includes('loadMaterialType'),
      disabled: that.mode === 'detail'
    },
    // 作业货位
    {
      name: 'workGoodsPosition',
      value: row.workGoodsPosition || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.workGoodsPosition'),
      data: that.workGoodsPositionList,
      rules: mapVerify(['required']),
      disabled: that.mode === 'detail' || that.isWorkGoodsPositionDisabled,
      isShow: that.propKeys.includes('workGoodsPosition')
    },
    // 作业动作
    {
      name: 'workAction',
      value: row.workAction || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.workAction'),
      rules: mapVerify(['required']),
      disabled: true,
      data: that.workActionPropList,
      isShow: that.propKeys.includes('workAction')
    },
    // 动作执行
    {
      name: 'actionExec',
      value: row.actionExec || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.actionExec'),
      disabled: true,
      data: that.actionExecPropList,
      isShow: that.propKeys.includes('actionExec'),
      rules: mapVerify(['required'])
    },
    // 移入库位
    {
      name: 'shiftInFactoryPositionProp',
      value: row.shiftInFactoryPositionProp || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.shiftInFactoryPosition'),
      data: that.shiftInFactoryPositionPropList,
      isShow: that.propKeys.includes('shiftInFactoryPositionProp'),
      disabled: that.mode === 'detail',
      rules: mapVerify(['required'])
    },
    // 货位状态
    {
      name: 'goodsPositionStatus',
      value: row.goodsPositionStatus ?? 0,
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.goodsPositionStatus'),
      data: that.goodsPositionStatusPropList,
      isShow: that.propKeys.includes('goodsPositionStatus'),
      disabled: that.mode === 'detail',
      rules: mapVerify(['required'])
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onPropFormChange
    }
  })
  return formData.filter((item) => item.isShow)
}

// 搬运模式属性表单
export const getCarryPropFormData = (that, row) => {
  const formData = [
    // 触发位置
    {
      name: 'triggerPosition',
      value: row.triggerPosition || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.triggerPosition'),
      rules: mapVerify(['required']),
      data: that.factoryPositionList,
      disabled: that.mode === 'detail' || !row.isEdit
    },
    // 触发操作
    {
      name: 'triggerOperate',
      value: row.triggerOperate || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.triggerOperate'),
      rules: mapVerify(['required']),
      data: that.pdaTriggerOperateList,
      disabled: that.mode === 'detail' || !row.isEdit
    },
    // 操作任务
    {
      name: 'triggerTask',
      value: row.triggerTask || '',
      span: 24,
      component: 'elSelect',
      adaptionw: true,
      showWordLimit: false,
      label: that.$t('lang.gles.workflow.propName.triggerTask'),
      rules: mapVerify(['required']),
      data: that.pdaTriggerTaskList,
      disabled: that.mode === 'detail' || !row.isEdit
    }
  ]
  formData.forEach((item) => {
    if (!item.mchange) {
      item.mchange = that.onPropFormChange
    }
  })
  return formData
}

export const getRefTableItem = (that) => {
  const searchTableItem = [
    // 引用工位编码
    {
      prop: 'workPositionCode',
      label: `${that.$t('lang.gles.workflow.refWorkPosition')}`,
      isShow: true
    },
    // 工位名称
    {
      prop: 'workPositionName',
      label: that.$t('lang.gles.workflow.workPositionName'),
      isShow: true
    },
    // 作业类型
    {
      prop: 'workTypeName',
      label: `${that.$t('lang.gles.workflow.workType')}`,
      isShow: true
    },
    // 作业类型状态
    {
      prop: 'enableStatus',
      label: `${that.$t('lang.gles.base.usableFlag')}`,
      isShow: true,
      minWidth: 140,
      slotName: 'enableStatus'
    }
  ]
  return searchTableItem
}
