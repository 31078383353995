<template>
  <div class="basic-data-list">
    <query-view
      ref="myQueryView"
      name="codeOrName"
      :value.sync="baseFormModel.codeOrName"
      :placeholder="$t('lang.gles.common.pleaseInputCodeOrName')"
      :query-list="moreQueryData"
      @baseSearch="handleBaseSearch"
      @moreSearch="handleMoreSearch"
    />
    <el-card class="table-card">
      <div class="operator-wrapper">
        <el-button type="primary" icon="el-icon-plus" @click="updateCom">
          {{ $t('lang.gles.common.addNew') }}
        </el-button>
        <!-- 列设置 -->
        <column-config :list.sync="columnConfigList" />
      </div>
      <m-table
        :table-item="tableItem"
        :table-data="tableData"
        :page-data="pageData"
        :extend-config="tableExtendConfig"
        @editItem="editItem"
        @delItem="delItem"
        @copyItem="copyItem"
        @makeNodeItem="makeNodeItem"
        @pageChange="pageChange"
      >
        <template #clickSlot="{ row }">
          <span class="list-detail-item" @click="handleDetail(row)">
            {{ row.templateCode }}
          </span>
        </template>
        <template #usableFlag="{ row }">
          <el-switch
            :value="row.templateStatus"
            class="switch"
            :active-text="$t('lang.gles.common.enable')"
            :inactive-text="$t('lang.gles.common.disable')"
            :active-value="0"
            :inactive-value="1"
            :disabled="row.executeMode === 0 && !row.nodeCount"
            @click.native="handleStateChange(row)"
          />
        </template>
      </m-table>
    </el-card>
    <!-- 详情 -->
    <detail-dialog
      v-if="detailDialogVisible"
      :visible.sync="detailDialogVisible"
      :mode="mode"
      :row-detail="row"
    />
  </div>
</template>
<script>
import ColumnConfig from '@/components/columnConfig'
import QueryView from '@/components/moreQuery/queryView.vue'
import MTable from '@/libs_sz/components/MTable/MTable'
import { getMoreQueryFormData, getSearchTableItem } from '../data'
import * as types from '@/store/mutationTypes'
import listMixins from '@/mixins/listMixins'
import commonMixins from '@/mixins/commonMixins'
import { mapState } from 'vuex'
import DetailDialog from './detail.vue'
export default {
  name: 'WorkTemplateList',
  components: {
    ColumnConfig,
    QueryView,
    MTable,
    DetailDialog
  },
  mixins: [listMixins, commonMixins],
  data() {
    return {
      tableData: [],
      formModel: {},
      detailDialogVisible: false,
      execTemplateList: [],
      baseFormModel: {}
    }
  },
  computed: {
    ...mapState('workflow', ['robotTypeList', 'allocStrategyList', 'executeModeList', 'carryPositionList']),
    ...mapState('base', ['statusFlagList']),
    moreQueryData() {
      return getMoreQueryFormData(this, this.formModel)
    },
    /**
     * 默认的表格列表项
     */
    defaultTableItem() {
      return getSearchTableItem(this)
    },
    /**
     * 渲染的表格列表项  列设置
     */
    tableItem() {
      let list = this.defaultTableItem
      if (this?.columnConfigList?.length) {
        list = this.defaultTableItem.filter(
          (item, i) =>
            item.name === this.columnConfigList[i]?.name &&
            this.columnConfigList[i]?.isShow
        )
      }
      return list
    }
  },
  created() {
    this.initSelectList()
    this.tableExtendConfig = {
      ...this.tableExtendConfig,
      operateWidth: '200px',
      operate: [
        {
          event: 'editItem',
          label: this.$t('lang.gles.common.edit'),
          getDisabled: this.getEditDisabled
        },
        {
          event: 'makeNodeItem',
          label: this.$t('lang.gles.workflow.makeNode'),
          getDisabled: this.getMakeNodeDisabled
        },
        {
          event: 'copyItem',
          label: this.$t('lang.gles.common.copy')
        },
        {
          event: 'delItem',
          label: this.$t('lang.gles.common.delete'),
          confirm: true,
          confirmMessage: this.$t('lang.gles.common.deleteConfirmMsg0'),
          customClass: 'danger',
          getDisabled: this.getDelDisabled
        }
      ]
    }
  },
  mounted() {
    this.doSearch(this.baseFormModel)
  },
  methods: {
    /**
     * 初始化下拉列表
     */
    async initSelectList() {
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
      if (!this.allocStrategyList?.length) {
        this.$store.dispatch('workflow/getAllocStrategyList')
      }
      if (!this.statusFlagList?.length) {
        this.$store.dispatch('base/getStatusFlagList')
      }
      if (!this.executeModeList?.length) {
        this.$store.dispatch('workflow/getExecuteModeList')
      }
      if (!this.carryPositionList?.length) {
        this.$store.dispatch('workflow/getCarryPositionList')
      }
      this.getExecTemplateList()
      this.getFactoryPositionList()
    },
    isDelDisabled(row) {
      return !row.templateStatus
    },
    isMakeNodeItemDisabled(row) {
      return !row.templateStatus
    },
    isEditDisabled(row) {
      return !row.templateStatus
    },
    /**
		 * 机器人类型切换事件
		 * 1、 机器人类型双举升： 执行模板加载GMS复合机器人模板数据；默认填充KL-ROBOT;
			 2、 机器人类型悬臂：执行模板加载GMS复合机器人模板数据；默认填充KA-ROBOT;
			 3、 机器人类型举升： 执行模板加载GMS举升机器人模板数据；默认填充ML-ROBOT；
		 */
    async handleRobotType(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      await this.getExecTemplateList({ robotType: val })
      this.formModel.execTemplate = ''
    },
    // 新增
    updateCom({ row }) {
      this.$emit('updateCom', {
        currentCom: 'WorkTemplateAdd',
        mode: 'add',
        row
      })
    },
    /**
     * 基础查询
     */
    handleBaseSearch(data) {
      this.doSearch({ ...data })
    },
    /**
     * 更多查询
     */
    handleMoreSearch(data) {
      this.formModel = { ...data }
      this.doSearch({ ...data }, 'getWorkTemplateList')
    },
    /**
     * 接口查询
     */
    async doSearch(params, api = 'getWorkTemplateListByCodeOrName') {
      try {
        const { data, code } = await this.$httpService(
          this.$apiStore.workflow(api, this.pageData),
          params
        )
        this.$store.commit(types.IS_LOADING, false)
        if (code) return

        const {
          recordList = [],
          currentPage,
          pageSize,
          totalRecordCount: recordCount
        } = data || {}
        this.pageData = { currentPage, pageSize, recordCount }
        this.tableData = recordList || []
      } catch (error) {
        this.$store.commit(types.IS_LOADING, false)
      }
    },
    getMakeNodeDisabled(row) {
      return row.templateStatus === 0
    },
    getDelDisabled(row) {
      return row.templateStatus === 0
    },
    getEditDisabled(row) {
      return !row.templateStatus
    },
    /**
     * 启用 禁用
     * 1、未编排节点不可启用
     * 2、已被货位 || 库存 引用的模板不可禁用
     */
    async handleStateChange(row) {
      // 未编排节点不可启用
      if (row.executeMode === 0 && !row.nodeCount && row.templateStatus === 1) {
        // 模板启用失败，请添加模板节点
        this.$message.error(this.$t('lang.gles.workflow.checkMessage0'))
        return
      }
      if (row.refConfigs && row.templateStatus === 1) {
        // 模板启用失败，模板已被引用
        this.$message.error(this.$t('lang.gles.workflow.checkMessage1'))
        return
      }
      const { code } = await this.$httpService(
        this.$apiStore.workflow('changeTemplateStatus'),
        {
          id: row.id,
          status: row.templateStatus ? 0 : 1,
          executeMode: row.executeMode ?? 0
        }
      )
      if (code) return
      row.templateStatus = row.templateStatus ? 0 : 1
    },
    /**
     * 查看详情
     */
    handleDetail(row) {
      this.detailDialogVisible = true
      this.row = row
      this.mode = 'detail'
    },
    // 编辑模板
    editItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'workTemplateAdd',
        mode: 'edit',
        row
      })
    },
    // 编排节点
    makeNodeItem({ row }) {
      if (row.templateStatus === 0) {
        this.$message.error(this.$t('lang.gles.workflow.checkMessage2'))
        return
      }
      const mode = row.nodeCount ? 'edit' : 'add'
      this.$emit('updateCom', {
        currentCom: 'workTemplateMakeNode',
        mode,
        row
      })
    },
    /**
     * 复制模板
     */
    copyItem({ row }) {
      this.$emit('updateCom', {
        currentCom: 'WorkTemplateAdd',
        mode: 'copy',
        row
      })
    },
    /**
     * 删除模版
     * 1、模版状态为启用时，不可删除
     */
    delItem({ row }) {
      if (row.templateStatus === 0) {
        this.$message.error(this.$t('lang.gles.workflow.checkMessage3'))
        return
      }
      this.$httpService(this.$apiStore.workflow('deleteWorkTemplate'), {
        id: row.id
      }).then(({ code }) => {
        if (code) return
        this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
        if (this.tableData.length < 2) {
          this.pageData.currentPage = 1
        }
        this.doSearch(this.baseFormModel)
      })
    }
  }
}
</script>
