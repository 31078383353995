<template>
  <div class="props-wrapper" :class="[propsBoxWidth]">
    <h3 class="prop-title">
      {{ $t('lang.gles.workflow.nodeInfo') }}
    </h3>
    <m-form
      :form-data="baseFormData"
      :extend-config="formExtendConfig"
      label-position="right"
      :label-width="80"
      class="prop-form"
    />
    <i class="el-icon-arrow-left icon-arrow-left" @click="togglePropBox" />
  </div>
</template>
<script>
import { getPropFormData } from '../data'
import { mapState } from 'vuex'
import addMixins from '@/mixins/addMixins'
export default {
  mixins: [addMixins],
  props: {
    currentNode: {
      type: Object,
      default: () => ({})
    },
    propList: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      formModel: {},
      formExtendConfig: {
        isNeedBtn: false
      },
      propsBoxWidth: ''
    }
  },
  computed: {
    ...mapState('workflow', [
      'materialTypePropList',
      'shiftOutContainerPropList',
      'workGoodsPositionPropList',
      'workActionPropList',
      'actionExecPropList',
      'goodsPositionStatusPropList',
      'shiftInFactoryPositionPropList'
    ]),
    propKeys() {
      return Object.keys(this.formModel)
    },
    isWorkGoodsPositionDisabled() {
      return ![20, 50].includes(this?.currentNode?.nodeType)
    },
    /**
     * 作业货位下拉选项
     */
    workGoodsPositionList() {
      let list = []
      // 节点编码 1策略分配 2工位  3取料类型
      // nodeType 节点类型@10：取料 @20：放料 @30：设备上料 @40：设备下料 @50：理货'
      switch (this?.currentNode?.nodeType) {
        case 10:
          list = this.workGoodsPositionPropList.filter(
            (t) => t.value === '1'
          )
          break
        case 30:
        case 40:
          list = this.workGoodsPositionPropList.filter(
            (t) => t.value === '2'
          )
          break
        case 20:
          list = this.workGoodsPositionPropList.filter((t) =>
            ['1', '3'].includes(t.value)
          )
          break
        default:
          list = this.workGoodsPositionPropList
      }
      return list
    },
    baseFormData() {
      return getPropFormData(this, { ...this.formModel })
    }
  },
  watch: {
    propList() {
      this.initFormModel()
    }
  },
  created() {
    this.initSelectList()
    this.initFormModel()
  },
  methods: {
    initSelectList() {
      if (!this.robotTypeList?.length) {
        this.$store.dispatch('workflow/getRobotTypeList')
      }
      if (!this.allocStrategyList?.length) {
        this.$store.dispatch('workflow/getAllocStrategyList')
      }
      if (!this.workGoodsPositionPropList?.length) {
        this.$store.dispatch('workflow/getWorkGoodsPositionPropList')
      }
      if (!this.materialTypePropList?.length) {
        this.$store.dispatch('workflow/getMaterialTypePropList')
      }
      if (!this.workActionPropList?.length) {
        this.$store.dispatch('workflow/getWorkActionPropList')
      }
      if (!this.actionExecPropList?.length) {
        this.$store.dispatch('workflow/getActionExecPropList')
      }
      if (!this.goodsPositionStatusPropList?.length) {
        this.$store.dispatch('workflow/getGoodsPositionStatusPropList')
      }
      if (!this.shiftInFactoryPositionPropList?.length) {
        this.$store.dispatch('workflow/getShiftInFactoryPositionPropList')
      }
      if (!this.shiftOutContainerPropList?.length) {
        this.$store.dispatch('workflow/getShiftOutContainerPropList')
      }
    },
    togglePropBox() {
      this.propsBoxWidth = this.propsBoxWidth ? '' : 'arrow-prop'
    },
    /**
     * 初始化formModel
     */
    initFormModel() {
      console.log(this.currentNode)
      let formModel = {
        nodeName: this.$t(this.currentNode.nodeName),
        nodeDescription: this.$t(this.currentNode.nodeDescription)
      }
      this.propList.forEach((item) => {
        formModel = {
          ...formModel,
          [item.nodePropCode]: item.nodePropValue
        }
      })
      this.formModel = formModel
      console.log(this.formModel)
    },
    /**
     * 改变formModel
     */
    changeFormModel() {
      console.log('改变formModel')
    },
    /**
     * 表单项改变事件
     */
    onPropFormChange(val, formItem, changeFormValue, formModel) {
      this.$set(this.formModel, formItem.name, val)
      // 组装新propList
      const propList = this._.cloneDeep(this.propList)
      propList.forEach((prop) => {
        const propKey = prop.nodePropCode
        prop['nodePropValue'] = this.formModel[propKey]
      })
      const currentNode = this._.cloneDeep(this.currentNode)
      currentNode.nodeName = this.formModel.nodeName
      currentNode.nodeDescription = this.formModel.nodeDescription
      currentNode.props = propList
      this.$emit('update:prop-list', propList)
      this.$emit('update:current-node', currentNode)
    }
  }
}
</script>
<style lang="scss" scoped>
.props-wrapper {
  position: relative;
  flex: 0 0 260px;
  border: 1px solid #f0f0f0;
  min-height: calc(100vh - 220px);
  max-height: calc(100vh - 100px);
  &.arrow-prop {
    width: 10px;
    flex: 0 0 10px;
    // transform: translateX(80%);
    // animation: ripple 1s ease;
  }
  .prop-title {
    width: 260px;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    margin-bottom: 10px;
    background: #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    font-size: 14px;
  }
  .prop-form {
    padding: 0 10px;
  }
  .icon-arrow-left {
    position: absolute;
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
    color: #e0e0e0;
    cursor: pointer;
  }
}
@keyframes ripple {
    0% {
      width: 160px;
    }
    100% {
      opacity: 1;
      transform: scale(1);
      width: 1px;
    }
  }
</style>
