<template>
  <div class="node-list-wrapper">
    <i
      v-if="mode !== 'detail'"
      class="el-icon-circle-close icon-del"
      @click="$emit('delete')"
    />
    <div class="node-list">
      <v-draggable
        :value="nodeList"
        :group="{ name: 'node', pull: false, put: false }"
        :disabled="true"
        :move="draggableMove"
        :force-fallback="true"
        @input="$emit('update:node-list', $event)"
      >
        <transition-group>
          <div
            v-for="item in nodeList"
            :key="item.nodeOrder"
            class="node-item"
            :class="{ active: selectedNode.nodeType === item.nodeType }"
            @click="handleSelectNode(item)"
          >
            <div class="left-num">
              <span>0{{ item.nodeOrder }}</span>
            </div>
            <div class="right-content">
              <div class="node-name">
                {{ $t(item.nodeName) }}
              </div>
              <div class="node-prop">
                <template v-if="item.nodeType === 50">
                  <p>{{ getShiftOutContainerName(item) }}</p>
                  <p>{{ getShiftInFactoryPositionName(item) }}</p>
                </template>
                <template v-else>
                  <p>{{ getMaterialType(item) }}</p>
                  <p>{{ getWorkGoodsPositionName(item) }}</p>
                </template>
                <!-- <svg-icon v-if="i < nodeList.length -1" class="long-down-arrow" icon-class="long-down-arrow" /> -->
              </div>
            </div>
          </div>
        </transition-group>
      </v-draggable>
    </div>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import { mapState } from 'vuex'
export default {
  components: {
    'v-draggable': Draggable
  },
  props: {
    nodeList: {
      type: Array,
      default: () => []
    },
    selectedTemplate: {
      type: Object,
      default: () => ({})
    },
    robotType: {
      type: Number,
      default: 1
    },
    mode: {
      type: String,
      default: 'add'
    }
  },
  data() {
    return {
      selectedNode: {}
    }
  },
  computed: {
    ...mapState('workflow', [
      'materialTypePropList',
      'shiftOutContainerPropList',
      'workGoodsPositionPropList',
      'workActionPropList',
      'actionExecPropList',
      'goodsPositionStatusPropList',
      'shiftInFactoryPositionPropList'
    ])
  },
  watch: {
    selectedTemplate() {
      this.selectedNode = {}
    }
  },
  methods: {
    draggableMove() {
      return false
    },
    handleDragInput(event) {
      if (event instanceof Promise) {
        return
      }
      this.$emit('update:node-list', event)
    },
    /**
     * 获取料类型
     */
    getMaterialType(node) {
      let typeStr = ''
      let label = ''
      switch (node.nodeType) {
        case 10:
          label = this.$t('lang.gles.workflow.propName.takeMaterialType')
          typeStr = `${label}：${this.getPropMaterialTypeName(
            node,
            'takeMaterialType'
          )}`
          break
        case 20:
          label = this.$t('lang.gles.workflow.propName.putMaterialType')
          typeStr = `${label}：${this.getPropMaterialTypeName(
            node,
            'putMaterialType'
          )}`
          break
        case 30:
          label = this.$t('lang.gles.workflow.propName.loadMaterialType')
          typeStr = `${label}：${this.getPropMaterialTypeName(
            node,
            'loadMaterialType'
          )}`
          break
        case 40:
          label = this.$t('lang.gles.workflow.propName.unloadMaterialType')
          typeStr = `${label}：${this.getPropMaterialTypeName(
            node,
            'unloadMaterialType'
          )}`
          break
        default:
          break
      }
      return typeStr
    },
    /**
     * 根据属性key获取料类型的label值
     */
    getPropMaterialTypeName(node = {}, propKey = '') {
      const value = node?.props?.find(
        (n) => n.nodePropCode === propKey
      )?.nodePropValue
      return (
        this.materialTypePropList?.find((m) => m.value === value)?.label ?? ''
      )
    },
    /**
     * 获取作业货位label值
     */
    getWorkGoodsPositionName(node) {
      const value = node?.props?.find(
        (n) => n.nodePropCode === 'workGoodsPosition'
      )?.nodePropValue
      const label = this.$t('lang.gles.workflow.propName.workGoodsPosition')
      return `${label}：${
        this.workGoodsPositionPropList?.find((m) => m.value === value)?.label ??
        ''
      }`
    },
    /**
     * 获取移出容器label值
     */
    getShiftOutContainerName(node) {
      const value = node?.props?.find(
        (n) => n.nodePropCode === 'shiftOutContainer'
      )?.nodePropValue
      const label = this.$t('lang.gles.workflow.propName.shiftOutContainer')
      return `${label}：${
        this.shiftOutContainerPropList?.find((m) => m.value === value)?.label ??
        ''
      }`
    },
    /**
     * 获取移入库位label值
     */
    getShiftInFactoryPositionName(node) {
      const value = node?.props?.find(
        (n) => n.nodePropCode === 'shiftInFactoryPositionProp'
      )?.nodePropValue
      const label = this.$t(
        'lang.gles.workflow.propName.shiftInFactoryPosition'
      )
      return `${label}：${
        this.shiftInFactoryPositionPropList?.find((m) => m.value === value)
          ?.label ?? ''
      }`
    },

    handleSelectNode(node) {
      this.selectedNode = node
      this.$emit('clickNode', { ...node })
    }
  }
}
</script>
<style lang="scss" scoped>
.node-list-wrapper {
  position: relative;
  width: 480px;
  // width: 60%;
  min-height: 480px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  padding: 40px 20px;
  border-radius: 5px;
  text-align: center;
  .icon-del {
    position: absolute;
    right: -8px;
    top: -10px;
    font-size: 24px;
    color: #e0e0e0;
    cursor: pointer;
  }
  .node-list {
    .node-item {
      position: relative;
      display: flex;
      align-items: center;
      padding: 10px;
      height: 100px;
      border: 1px solid #fff;
      border-radius: 4px;
      cursor: pointer;
      &:not(:first-child) {
        // margin-top: 50px;
      }
      .long-down-arrow {
        position: absolute;
        left: 50%;
        bottom: -40px;
        font-size: 30px;
        transform: scaleY(1.5);
      }
      &:hover,
      &.active {
        border: 1px solid #409eff;
        border-radius: 4px;
      }
      .left-num {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        border-radius: 50%;
        background: #409eff;
        color: #fff;
        font-size: 10px;
        text-align: center;
        margin-right: 20px;
      }
      .right-content {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        border: 1px solid #f0f0f0;
        // background: #f0f0f0;
        border-radius: 4px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        .node-name {
          flex: 0 0 120px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          // background: #efefef;
          background: #f0f0f0;
          // background: #409eff;
          // color: #fff;
        }
        .node-prop {
          padding: 10px;
          text-align: left;
        }
      }
    }
  }
}
</style>
