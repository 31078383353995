<template>
  <div class="basic-data-add">
    <header-path v-if="mode !== 'detail'" :paths="headerPaths" />
    <el-card class="make-node-card" :class="{'detail-card': mode === 'detail' }" :shadow="mode === 'detail' ? 'never' : 'always'">
      <h3 v-if="mode !== 'detail'" class="title">
        {{ $t('lang.gles.workflow.templateName') }}： {{ templateName }}
      </h3>
      <div class="node-content">
        <node-template
          v-if="mode !== 'detail'"
          :robot-type="rowDetail.robotType"
          :template-list="templateList"
          :selected-template.sync="selectedTemplate"
          :selected-template-code="standardTemplateCode"
          :added-node-list="nodeList"
          @onDragEnd="handleTemplateDragEnd"
          @onChange="handleTemplateChange"
        />
        <div
          class="stage-wrapper"
          :class="{
            'flex-end': !nodeList.length || rowDetail.executeMode === 1
          }"
        >
          <node-list
            v-if="nodeList.length && rowDetail.executeMode === 0"
            :mode="mode"
            :node-list.sync="nodeList"
            :selected-template.sync="selectedTemplate"
            :robot-type="rowDetail.robotType"
            @clickNode="handleClickNode"
            @delete="handleClearNodeList"
          />
          <div v-if="mode !== 'detail'" class="operate-btns">
            <el-button v-debounce="handleSaveAndEnable" type="primary">
              {{ $t('lang.gles.common.saveAndEnable') }}
            </el-button>
            <el-button v-debounce="handleSave" type="primary">
              {{ $t('lang.gles.common.save') }}
            </el-button>
            <el-button @click="handleCancel">
              {{ $t('lang.gles.common.cancel') }}
            </el-button>
          </div>
        </div>
        <node-prop
          v-if="propList.length && rowDetail.executeMode === 0"
          :key="currentNode.nodeType"
          ref="propForm"
          :current-node.sync="currentNode"
          :prop-list.sync="propList"
          :mode="mode"
          @update:prop-list="handleUpdatePropList"
          @update:current-node="handleUpdateCurrentNode"
        />
        <carry-node-prop
          v-if="rowDetail.executeMode === 1"
          :work-template-id="rowDetail.id"
          :mode="mode"
          :p-node-list.sync="nodeList"
          :execute-mode="rowDetail.executeMode"
        />
      </div>
    </el-card>
  </div>
</template>
<script>
import HeaderPath from '@/components/headerPath'
import NodeTemplate from './nodeTemplate.vue'
import NodeList from './nodeList.vue'
import NodeProp from './nodeProp.vue'
import CarryNodeProp from './carryNodeProp.vue'
import { mapState } from 'vuex'
import { delNullField } from '@/utils/utils'
export default {
  name: 'WorkTemplateMakeNode',
  components: {
    HeaderPath,
    NodeTemplate,
    NodeList,
    NodeProp,
    CarryNodeProp
  },
  props: {
    mode: {
      type: String,
      default: 'add'
    },
    rowDetail: {
      type: Object,
      default: () => ({
        robotType: 1
      })
    }
  },
  data() {
    return {
      selectedTemplate: {},
      nodeList: [],
      templateList: [],
      propList: [],
      nodeType: 'QL',
      currentNode: {},
      standardTemplateCode: null
    }
  },
  computed: {
    ...mapState('workflow', [
      'factoryList',
      'robotTypeList',
      'allocStrategyList',
      'workGoodsPositionPropList',
      'materialTypePropList',
      'workActionPropList',
      'actionExecPropList',
      'goodsPositionStatusPropList'
    ]),
    headerPaths() {
      return [this.$t('lang.gles.workflow.makeWorkNode')]
    },
    /**
     * 机器人系列名称
     */
    templateName() {
      let name = ''
      switch (this?.rowDetail?.robotType) {
        case 1:
          // 双举升系列模板
          name = this.$t('lang.gles.workflow.doubleLiftSeriesTemplate')
          break
        case 2:
          // 悬臂系列模板
          name = this.$t('lang.gles.workflow.cantileverSeriesTemplate')
          break
        case 3:
          // 潜伏系列模板
          name = this.$t('lang.gles.workflow.lurkSeriesTemplate')
          break
        case 4:
          // 滚筒系列
          name = this.$t('lang.gles.workflow.rollerSeriesTemplate')
          break
        default:
          break
      }
      if (this.rowDetail.executeMode === 1) {
        name = this.$t('lang.gles.workflow.carryModeTemplate')
      }
      return name
    }
  },
  created() {
    if (this.rowDetail.executeMode === 0) {
      this.getStandardTemplates()
      if (this.rowDetail?.id) {
        this.getWorkTemplateNodesPropsInfo()
      }
    }
  },
  methods: {
    /**
     * 获取标准模板列表
     */
    async getStandardTemplates() {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getStandardTemplates'),
        {
          robotType: this?.rowDetail?.robotType ?? 1
        }
      )
      if (code) return
      this.templateList = delNullField(data) || []
    },
    /**
     * 获取标准模板列表
     */
    async getWorkTemplateNodesPropsInfo() {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getWorkTemplateNodesPropsInfo'),
        {
          workTemplateCode: this.rowDetail.templateCode
        }
      )
      if (code) return
      this.nodeList = delNullField(data) || []
      this.propList = this.nodeList[0]?.props || []
      this.currentNode = this.nodeList[0]
      this.standardTemplateCode = this.nodeList[0]?.standardTemplateCode
      this.selectedTemplate = this.templateList?.find(
        (t) => t.templateCode === this.standardTemplateCode
      )
    },
    /**
     * 删除模板
     */
    handleClearNodeList() {
      this.nodeList = []
      this.selectedTemplate = {}
      this.propList = []
    },
    /**
     * 拖拽模板
     */
    handleTemplateDragEnd(nodeList) {
      if (!nodeList.length) {
        this.$message.error(this.$t('标准模板对应节点为空'))
        return
      }
      this.nodeList = nodeList
      this.currentNode = nodeList[0]
      this.nodeType = nodeList[0].nodeType
      this.propList = nodeList[0].props
    },
    /**
     * 添加模板变化事件
     */
    handleTemplateChange(nodeList) {
      this.handleTemplateDragEnd(nodeList)
    },
    /**
     * 选中节点
     */
    handleClickNode(node) {
      for (const item of this.nodeList) {
        if (item.nodeType === node.nodeType) {
          this.propList = item.props
          this.nodeType = item.nodeType
          this.currentNode = item
          return
        }
      }
    },
    /**
     * 更新节点属性信息
     */
    handleUpdatePropList(propList) {
      console.log(propList)
    },
    /**
     * 更新节点属性信息
     */
    handleUpdateCurrentNode(node = {}) {
      for (const n of this.nodeList) {
        if (n.nodeType === node.nodeType) {
          n.nodeName = node.nodeName
          n.nodeDescription = node.nodeDescription
          n.props = node.props
          return
        }
      }
    },
    /**
     * 保存并启用节点
     */
    async handleSaveAndEnable() {
      // 搬运模式
      if (this.rowDetail.executeMode === 1) {
        this.saveAndEnable()
        return
      }
      // 标准模式
      if (!this.nodeList?.length) {
        this.$message.error(this.$t('lang.gles.workflow.checkMessage6'))
        return
      }
      // 校验属性非空
      if (!this.checkNodeProp()) {
        return
      }
      this.$refs.propForm?.$children[0]
        ?.getValidateFormModel()
        .then(async (model) => {
          this.saveAndEnable()
        })
    },
    /**
     * 保存并启用
     */
    async saveAndEnable() {
      const params = {
        workTemplateCode: this.rowDetail.templateCode,
        standardTemplateCode: this.selectedTemplate.templateCode,
        nodes: this.rowDetail.executeMode === 0 ? this.nodeList : []
      }
      const { code } = await this.$httpService(
        this.$apiStore.workflow('saveAndEnableNode'),
        params
      )
      if (code !== 0) {
        return
      }
      this.$message.success(
        this.$t('lang.gles.common.saveAndEnableSuccessfully')
      )
      setTimeout(() => {
        this.handleCancel()
      })
    },
    /**
     * 保存编排节点
     */
    async handleSave() {
      if (this.rowDetail.executeMode === 0 && !this.checkNodeProp()) {
        return
      }
      // 属性节点表单校验
      const params = {
        workTemplateCode: this.rowDetail?.templateCode ?? '',
        standardTemplateCode: this.selectedTemplate?.templateCode ?? '',
        nodes: this.rowDetail.executeMode === 0 ? delNullField(this.nodeList) : []
      }
      const { code } = await this.$httpService(
        this.$apiStore.workflow('saveNode'),
        params
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.saveSuccessfully'))
      setTimeout(() => {
        this.handleCancel()
      })
    },
    /**
     * 校验所有节点的属性信息
     */
    checkNodeProp() {
      for (const node of this.nodeList) {
        if (!node.nodeName) {
          this.$message.error(this.$t('lang.gles.workflow.checkMessage8'))
          return
        }
        if (node.nodeType === 10) {
          return this.checkQLProp(node)
        } else if (node.nodeType === 20) {
          return this.checkFLProp(node)
        } else if (node.nodeType === 30) {
          return this.checkSLProp(node)
        } else if (node.nodeType === 40) {
          return this.checkXLProp(node)
        } else if (node.nodeType === 50) {
          return this.checkLHProp(node)
        }
      }
      return true
    },
    /**
     * 校验取料节点
     */
    checkQLProp(node = {}) {
      for (const prop of node.props) {
        if (prop.nodePropCode === 'takeMaterialType' && !prop.nodePropValue) {
          this.$message.error(this.$t('lang.gles.workflow.checkMessage9'))
          return false
        }
      }
      return true
    },
    /**
     * 校验放料节点
     */
    checkFLProp(node = {}) {
      for (const prop of node.props) {
        if (prop.nodePropCode === 'putMaterialType' && !prop.nodePropValue) {
          this.$message.error(this.$t('lang.gles.workflow.checkMessage10'))
          return false
        }
      }
      return true
    },
    /**
     * 校验设备下料节点
     */
    checkXLProp(node = {}) {
      for (const prop of node.props) {
        if (prop.nodePropCode === 'unloadMaterialType' && !prop.nodePropValue) {
          this.$message.error(this.$t('lang.gles.workflow.checkMessage11'))
          return false
        }
      }
      return true
    },
    /**
     * 校验设备上料节点
     */
    checkSLProp(node = {}) {
      for (const prop of node.props) {
        if (prop.nodePropCode === 'loadMaterialType' && !prop.nodePropValue) {
          this.$message.error(this.$t('lang.gles.workflow.checkMessage12'))
          return false
        }
      }
      return true
    },
    /**
     * 校验理货节点
     */
    checkLHProp(node = {}) {
      for (const prop of node.props) {
        if (prop.nodePropCode === 'shiftOutContainer' && !prop.nodePropValue) {
          this.$message.error(this.$t('lang.gles.workflow.checkMessage13'))
          return false
        }
      }
      return true
    },
    /**
     * 取消编排节点
     */
    handleCancel() {
      this.$emit('updateCom', {
        currentCom: 'WorkTemplateList'
      })
    }
  }
}
</script>
<style lang="scss">
.make-node-card {
  min-height: calc(100vh - 200px);
  font-size: 14px;
  &.detail-card.el-card {
    border: none
  }
  .title {
    padding: 10px;
    font-size: 14px;
  }
  .node-content {
    display: flex;
    min-height: calc(100vh - 200px);
    border-top: 1px solid #efefef;
    overflow: hidden;
    .stage-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      &.flex-end {
        justify-content: flex-end;
      }
      .operate-btns {
        flex: 0 0 50px;
        margin-top: 20px;
      }
    }
  }
  .el-card__body {
    height: 100%;
    padding: 0;
  }
}
</style>
