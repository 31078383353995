<template>
  <el-drawer
    :visible="visible"
    size="80%"
    custom-class="gles-drawer"
    :with-header="false"
    @update:visible="$emit('update:visible', $event)"
  >
    <div class="drawer-wrapper">
      <div class="drawer-operate-btn">
        <el-button type="primary" @click="handleEdit">
          {{ $t('lang.gles.common.edit') }}
        </el-button>
        <el-button
          type="primary"
          :disabled="rowDetail.templateStatus === 0"
          @click="handleMakeNode"
        >
          {{ $t('lang.gles.workflow.makeNode') }}
        </el-button>
        <el-button type="primary" @click="handleCopy">
          {{ $t('lang.gles.common.copy') }}
        </el-button>
        <el-button
          type="danger"
          :disabled="rowDetail.templateStatus === 0"
          @click="handleDelete"
        >
          {{ $t('lang.gles.common.delete') }}
        </el-button>
        <i class="el-icon-close" @click="$emit('update:visible', false)" />
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClickTab">
        <el-tab-pane
          :label="$t('lang.gles.workflow.templateBasicInfo')"
          name="first"
        />
        <el-tab-pane
          :label="$t('lang.gles.workflow.templateQuoteInfo')"
          name="second"
        />
      </el-tabs>
      <section v-if="activeName === 'first'" class="template-basic-info">
        <h3 class="title">
          {{ $t('lang.gles.common.basicInfo') }}
        </h3>
        <WorkTemplateAdd :row-detail="rowDetail" mode="detail" />
        <h3 v-if="nodeList.length" class="title">
          {{ $t('lang.gles.workflow.nodeInfo') }}
        </h3>
        <WorkTemplateMakeNode :row-detail="rowDetail" mode="detail" />
      </section>
      <section v-if="activeName === 'second'" class="template-quote-info">
        <h3 class="title">
          {{ $t('lang.gles.workflow.quoteInfo') }}
        </h3>
        <m-table
          :table-item="refTableItem"
          :table-data="refTableData"
          :page-data="pageData"
        >
          <template #enableStatus="{ row }">
            <el-switch
              v-model="row.enableStatus"
              class="switch"
              :active-text="$t('lang.gles.common.enable')"
              :inactive-text="$t('lang.gles.common.disable')"
              :active-value="0"
              :inactive-value="1"
              disabled
            />
          </template>
        </m-table>
      </section>
    </div>
  </el-drawer>
</template>
<script>
import addMixins from '@/mixins/addMixins'
import commonMixins from '@/mixins/commonMixins'
import { getRefTableItem } from '../data'
import WorkTemplateAdd from './add'
import WorkTemplateMakeNode from './node'

export default {
  name: 'WorkTemplateDetail',
  components: {
    WorkTemplateAdd,
    WorkTemplateMakeNode
  },
  mixins: [addMixins, commonMixins],
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'detail'
    },
    rowDetail: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      activeName: 'first',
      extendFormConfig: {
        isNeedBtn: false,
        isGroup: false
      },
      formModel: {},
      nodeList: [],
      propList: [],
      currentNode: {},
      totalRefTableData: [],
      refTableData: [],
      pageData: {
        currentPage: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    refTableItem() {
      return getRefTableItem(this, {})
    }
  },
  methods: {
    /**
     * 前端分页
     */
    getCurrentPageData(list = []) {
      const { currentPage, pageSize } = this.pageData
      return (
        list.filter((item, i) => {
          const startIdx = (currentPage - 1) * pageSize
          const endIdx = currentPage * pageSize
          const idx = i + 1
          return idx > startIdx && idx <= endIdx
        }) ?? []
      )
    },
    /**
     * 分页切换
     */
    pageChange(pageData) {
      this.pageData = { ...this.pageData, ...pageData }
      this.tableData = this.getCurrentPageData(this.totalRefTableData)
    },
    handleClickTab() {
      console.log('切换tab')
    },
    /**
     * 选中节点
     */
    handleClickNode(node) {
      for (const item of this.nodeList) {
        if (item.nodeType === node.nodeType) {
          this.propList = item.props
          this.nodeType = item.nodeType
          this.currentNode = item
          return
        }
      }
    },
    /**
     * 编辑
     */
    handleEdit() {
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'WorkTemplateAdd',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 编排节点
     */
    handleMakeNode() {
      if (this.rowDetail?.templateStatus === 0) {
        this.$message.warning(this.$t('lang.gles.workflow.checkMessage2'))
        return
      }
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'WorkTemplateMakeNode',
        mode: 'edit',
        row: this.rowDetail
      })
    },
    /**
     * 复制节点
     */
    handleCopy() {
      this.$EventBus.$emit('page:updateCom', {
        currentCom: 'WorkTemplateAdd',
        mode: 'copy',
        row: this.rowDetail
      })
    },
    /**
     * 删除
     * 二次确认
     */
    async handleDelete() {
      // 二次确认
      this.$confirm(this.$t('lang.gles.common.deleteConfirmMsg0'), '', {
        confirmButtonText: this.$t('lang.gles.common.confirm'),
        cancelButtonText: this.$t('lang.gles.common.cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteTemplate(this.rowDetail)
      })
    },
    /**
     * 删除模板
     * 1、禁用状态的作业模板可删除
     * 2、启用状态作业模板不可删除，删除按钮置灰不可点击
     */
    async deleteTemplate(row) {
      if (row.templateStatus === 0) {
        this.$message.error(this.$t('lang.gles.workflow.checkMessage3'))
        return
      }
      const { code } = await this.$httpService(
        this.$apiStore.workflow('deleteWorkTemplate'),
        {
          id: row.id
        }
      )
      if (code) return
      this.$message.success(this.$t('lang.gles.common.deleteSuccessfully'))
      this.$emit('update:visible', false)
      this.$parent.doSearch(this.baseFormModel)
    }
  }
}
</script>
<style lang="scss" scoped>
.el-table__header-wrapper {
  overflow: auto;
}
/deep/ .el-drawer__header {
  margin-bottom: -30px;
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
.node-content {
  display: flex;
  min-height: calc(100vh - 324px);
  border-top: 1px solid #efefef;
  .stage-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    &.flex-end {
      justify-content: flex-end;
    }
    .operate-btns {
      flex: 0 0 50px;
    }
  }
}
</style>
