<template>
  <div class="template-template-wrapper">
    <h3 class="template-title">
      {{ $t(templateName) }}
    </h3>
    <div class="template-list">
      <v-draggable
        v-model="templateList"
        :group="{ name: 'node', pull: pullFlag, put: false }"
        animation="150"
        :move="onMove"
        :clone="cloneNodeList"
        :force-fallback="true"
        @start="onStart"
        @end="onEnd"
      >
        <transition-group>
          <div
            v-for="template in templateList"
            :key="template.templateOrder"
            class="template-item"
            :class="{
              active: selectedTemplate.templateCode === template.templateCode
            }"
            @click="handleChangeTemplate(template)"
          >
            <div class="template-icon">
              <svg-icon :icon-class="template.templateCode" />
            </div>
            {{ $t(template.templateName) }}
          </div>
        </transition-group>
      </v-draggable>
    </div>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import { delNullField } from '@/utils/utils'
const vm = window.vm
export default {
  components: {
    'v-draggable': Draggable
  },
  props: {
    templateName: {
      type: String,
      default: vm.$t('lang.gles.workflow.standardTemplate')
    },
    /**
     * 模版列表
     */
    templateList: {
      type: Array,
      default: () => []
    },
    /**
     * 机器人类型
     */
    robotType: {
      type: Number,
      default: 1
    },
    selectedTemplate: {
      type: Object,
      default: () => ({})
    },
    addedNodeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      nodeList: [],
      pullFlag: 'clone'
    }
  },
  watch: {
    addedNodeList: {
      handler() {
        this.pullFlag = !this.addedNodeList?.length ? 'clone' : 'false'
      },
      immediate: true
    }
  },
  methods: {
    onStart(e) {
      // console.log(e)
      // if (this.addedNodeList?.length) {
      //   this.$message.error(this.$t('lang.gles.workflow.checkMessage7'))
      //   this.pullFlag = 'false'
      //   return
      // }
    },
    onEnd(e) {
      if (this.addedNodeList?.length) {
        this.$message.error(this.$t('lang.gles.workflow.checkMessage7'))
        this.pullFlag = 'false'
        return
      }
      this.$emit('onDragEnd', [...this.nodeList])
    },
    onMove(e) {
      // return false
    },
    /**
     * 拖拽添加
     * @param {*} template
     */
    async cloneNodeList(template) {
      this.getTemplateNodeList(template)
      this.$emit('update:selected-template', template)
      return [...this.nodeList]
    },
    /**
     * 点击添加
     * @param {*} template
     */
    async handleChangeTemplate(template) {
      if (template.templateCode === this.selectedTemplate.templateCode) {
        return
      }
      if (this.addedNodeList?.length) {
        this.$message.error(this.$t('lang.gles.workflow.checkMessage7'))
        return
      }
      this.$emit('update:selected-template', template)
      await this.getTemplateNodeList(template)
      this.$emit('onChange', [...this.nodeList], template)
    },
    /**
     * 根据机器人类型和模板编号获取节点列表
     */
    async getTemplateNodeList(template) {
      const { data, code } = await this.$httpService(
        this.$apiStore.workflow('getTemplateNodeList'),
        {
          robotType: this?.robotType ?? 1,
          templateCode: template.templateCode
        }
      )
      if (code) return
      const nodeList = delNullField(data) || []
      this.nodeList = nodeList
    }
  }
}
</script>
<style lang="scss" scoped>
.template-template-wrapper {
  width: 200px;
  flex: 0 0 200px;
  padding: 5px 10px 10px;
  border: 1px solid #efefef;
  border-top: none;
  overflow-y: auto;
  // min-height: calc(100vh - 220px);
  // max-height: calc(100vh - 100px);
  .template-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
  }
  .template-list {
    .template-item {
      position: relative;
      width: 180px;
      height: 40px;
      line-height: 40px;
      border: 1px solid #efefef;
      border-radius: 4px;
      text-align: center;
      &:hover {
        border: 1px solid #409eff;
      }
      &.active {
        border: 1px solid #409eff;
        background: #409eff;
        color: #fff;
      }
      &:not(:first-child) {
        margin-top: 10px;
      }
      .template-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 22px;
      }
    }
  }
}
</style>
